import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Textarea, _mergeProps({
      ref: "input",
      name: _ctx.name,
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      error: !!_ctx.errorMessage,
      label: _ctx.label,
      rows: _ctx.rows
    }, _ctx.$attrs, {
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event))
    }), null, 16, ["name", "type", "placeholder", "error", "label", "rows", "modelValue"]),
    _withDirectives(_createVNode(_component_ErrorMessage, { class: "mt-2" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.errorMessage]
    ])
  ], 64))
}