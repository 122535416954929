import { vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c32e72de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-textarea" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", _mergeProps({
      ref: "textarea",
      class: ["base-textarea", { error: !!_ctx.errorMessage }],
      style: { width: _ctx.$attrs.width, height: _ctx.$attrs.height },
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textareaValue) = $event))
    }, _ctx.$attrs, {
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }), null, 16), [
      [_vModelText, _ctx.textareaValue]
    ]),
    (!!_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          class: "mt-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}