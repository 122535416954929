<template>
  <ValidationForm
    :validation-schema="schema"
    :initial-values="user"
    v-slot="{ meta }"
    @submit="changeProfile"
  >
    <Card title="プロフィール編集">
      <Label bold size="m" required>氏名</Label>
      <Flex class="mt-2">
        <Box width="250px">
          <VeeTextField name="lastName" placeholder="姓" size="m" />
        </Box>
        <Box width="250px" class="ml-4">
          <VeeTextField name="firstName" placeholder="名" size="m" />
        </Box>
      </Flex>

      <Label bold size="m" class="mt-5">ニックネーム(表示名)</Label>
      <Typography size="s" color="grey" class="mt-2">
        未設定の場合氏名が表示されます
      </Typography>
      <Box width="300px">
        <VeeTextField
          name="nickname"
          placeholder="特殊文字は使えません"
          maxlength="14"
          class="mt-2"
          size="m"
        />
      </Box>

      <Label bold size="m" class="mt-5">プロフィール</Label>
      <VeeTextarea
        name="userDescription"
        placeholder="140字まで"
        maxlength="140"
        rows="5"
        class="mt-1"
      />
    </Card>

    <Flex justify-center class="mt-6">
      <Button type="submit" :disabled="!meta.valid" :loading="isLoading">
        変更する
      </Button>
    </Flex>
  </ValidationForm>
</template>
<script>
import { defineComponent } from 'vue';
import { VALIDATION_SCHEMA } from '@/common/validation';
import { Form as ValidationForm } from 'vee-validate';
import * as Yup from 'yup';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
import Typography from '@/components/ui/Typography.vue';
import Label from '@/components/ui/Label.vue';
import VeeTextField from '@/components/veeValidate/VeeTextField.vue';
import VeeTextarea from '@/components/veeValidate/VeeTextarea.vue';
import { useProfile } from '@/composables/mypage';

export default defineComponent({
  name: 'mypageProfile',
  components: {
    Box,
    Button,
    Card,
    Label,
    ValidationForm,
    VeeTextarea,
    VeeTextField,
    Typography,
    Flex
  },
  setup() {
    const {
      firstName,
      lastName,
      nickname,
      userDescription
    } = VALIDATION_SCHEMA;
    const { changeProfile, user, isLoading } = useProfile();

    return {
      schema: Yup.object().shape({
        firstName,
        lastName,
        nickname,
        userDescription
      }),
      changeProfile,
      user,
      isLoading
    };
  }
});
</script>
