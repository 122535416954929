
import { defineComponent, computed, ref, Ref, watch } from 'vue';
import ErrorMessage from '@/components/ui/ErrorMessage.vue';

export default defineComponent({
  components: { ErrorMessage },
  props: {
    modelValue: String,
    name: String,
    rows: String,
    rowHeight: {
      type: Number,
      default: 30
    },
    expand: {
      type: Boolean,
      default: true
    },
    errorMessage: String
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const textareaValue = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });

    const textarea: Ref<HTMLInputElement | null> = ref(null);

    const calculateHeight = () => {
      if (!props.expand || textarea.value === null) return;
      textarea.value.style.height = '0';
      const height = textarea.value.scrollHeight;
      const minHeight = parseInt(props.rows || '2', 10) * props.rowHeight;
      textarea.value.style.height = `${Math.max(minHeight, height)}px`;
    };

    watch(
      () => textarea.value,
      textarea => {
        if (textarea) {
          calculateHeight();
        }
      }
    );

    return {
      textarea,
      textareaValue,
      onInput: () => calculateHeight()
    };
  }
});
