
import { defineComponent, watch } from 'vue';
import { useField } from 'vee-validate';
import ErrorMessage from '@/components/ui/ErrorMessage.vue';
import Textarea from '@/components/ui/Textarea.vue';

export default defineComponent({
  components: { ErrorMessage, Textarea },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    rows: String
  },
  setup: props => {
    const { value: inputValue, errorMessage } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    );

    watch(
      () => props.value,
      updatedValue => {
        inputValue.value = updatedValue;
      }
    );

    return {
      errorMessage,
      inputValue
    };
  }
});
